import React, { Component } from "react";
import { getUrl } from "../services/urls";

class Desktop extends Component {
  state = {
    servicios: [],
  };

  componentDidMount() {
    const headers = {
      "legal-token": "legal " + this.props.token,
    };
    fetch(getUrl() + "/legal/service/admin", {
      method: "GET",
      headers: headers,
    }).then((response) => {
      console.log(response);
      if (response.ok) {
        response.json().then((json) => this.setState({ servicios: json }));
      } else {
        this.setState({ servicios: [] });
      }
    });
  }

  render() {
    return (
      <div className="App">
        <h2>Servicios</h2>
        <div class="table-responsive small">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">TELEFONO</th>
                <th scope="col">USUARIO</th>
                <th scope="col">STATUS</th>
                <th scope="col">FECHA DE CREACION</th>
              </tr>
            </thead>
            <tbody>
              {this.state.servicios.map((row) => (
                <tr>
                  <td>{row.id}</td>
                  <td>{row.telefono}</td>
                  <td>{row.usuario}</td>
                  <td>{row.status}</td>
                  <td>{row.fechaCreacion}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Desktop;
