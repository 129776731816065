import React, { Component } from "react";
import { getUrl } from "../services/urls";

class Login extends Component {
  state = {
    user: "",
    password: "",
    fail: false,
  };

  changeUser = (user) => {
    this.setState({ user });
  };

  changePassword = (password) => {
    this.setState({ password });
  };

  join = () => {
    const headers = {
      username: this.state.user,
      password: this.state.password,
    };
    fetch(getUrl() + "/legal/admin-user/get-token", {
      method: "GET",
      headers: headers,
    }).then((response) => {
      console.log(response);
      if (response.ok) {
        response.text().then((token) => this.props.setToken(token));
      } else {
        this.setState({ fail: true });
      }
    });
  };

  backJoin = () => {
    this.setState({ fail: false });
  };

  render() {
    return this.state.fail ? (
      <div className="form-signin w-100 m-auto">
        <label>Falla al ingresar</label>
        <button
          className="btn btn-primary w-100 py-2"
          type="submit"
          onClick={this.backJoin}
        >
          Atras
        </button>
      </div>
    ) : (
      <div className="d-flex align-items-center py-4 bg-body-tertiary">
        <div className="form-signin w-100 m-auto ">
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value={this.state.user}
              onChange={(value) => this.changeUser(value.target.value)}
            />
            <label for="floatingInput">Email address</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              value={this.state.password}
              onChange={(value) => this.changePassword(value.target.value)}
            />
            <label for="floatingPassword">Password</label>
          </div>
          <button
            className="btn btn-primary w-100 py-2"
            type="submit"
            onClick={() => this.join()}
          >
            Entrar
          </button>
        </div>
      </div>
    );
  }
}

export default Login;
