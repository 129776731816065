import React, { Component } from "react";
import Login from "./login";
import Desktop from "./desktop";

class Admin extends Component {
  state = {
    logged: false,
    token: "",
  };

  setToken = (token) => {
    this.setState({ token: token, loggin: true });
  };

  render() {
    return this.state.loggin ? (
      <Desktop token={this.state.token} />
    ) : (
      <Login setToken={this.setToken} />
    );
  }
}

export default Admin;
